'use strict';

const log = require('./logger');

/**
 * Generate a new deck
 */

module.exports = () => {
  log.info('Generating deck');
  const cards = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];
  return [
    ...cards.map((x, order) => ({ suit: 'diamond', number: x, order })),
    ...cards.map((x, order) => ({ suit: 'club', number: x, order })),
    ...cards.map((x, order) => ({ suit: 'heart', number: x, order })),
    ...cards.map((x, order) => ({ suit: 'spade', number: x, order }))
  ];
};
