<script>
  import { user_id, user_names, user_avatars } from '../utils/stores';
  import ws_send from '../utils/ws-send';

  export let show_profile = true;
  let settingsIcons;
  let settingsScreen;
  let nameInput = '';
  let nameInputDefault = null;

  $: {
    nameInputDefault = $user_names[$user_id];
  }

  export function profileIconClick(e) {
    settingsIcons.style.display = 'none';
    settingsScreen.style.display = 'block';
    nameInput = nameInputDefault;
  }

  function settingsClose(e) {
    settingsIcons.style.display = 'block';
    settingsScreen.style.display = 'none';
  }

  function nameSave(name) {
    ws_send({ op: 'set_user_info', name });
    nameInputDefault = name;
    nameInput = name;
  }

  function avatarSelect(num) {
    ws_send({ op: 'set_user_info', avatar: num });
  }
</script>

<style>
  div.settings {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 50;
    color: #fff;
  }

  @media all and (orientation: landscape) {
    div.profile {
      float: right;
      width: 75px;
      height: 75px;
      font-size: 50px;
      text-align: center;
      cursor: pointer;
    }

    div.profile span {
      cursor: pointer;
    }

    div.profile span:hover {
      color: #ff0;
    }

    div.music {
      float: right;
      width: 75px;
      height: 75px;
      font-size: 50px;
      text-align: center;
      cursor: pointer;
    }

    div.music span {
      cursor: pointer;
    }

    div.music span:hover {
      color: #ff0;
    }
  }

  @media all and (orientation: portrait) {
    div.profile {
      width: 50px;
      height: 50px;
      font-size: 40px;
      cursor: pointer;
    }

    div.music {
      width: 50px;
      height: 50px;
      font-size: 40px;
      cursor: pointer;
    }
  }

  div.settingsScreen {
    display: none;
    position: absolute;
    z-index: 51;
    top: 5vh;
    left: 5vw;
    width: 90vw;
    height: 90vh;
    margin: auto;
    font-size: 30px;
    text-align: center;
    padding: 0;
    color: #444;
    background-color: rgba(220, 250, 250, 1);
    border-radius: 10px;
    border: 1px solid rgba(30, 30, 30, 0.25);
    box-shadow: 1px 1px 5vmax rgba(0, 0, 0, 0.5);
    overflow: auto;
  }

  div.close {
    position: absolute;
    top: 1vh;
    right: 1vw;
    font-size: 60px;
    padding: 0 10px;
    display: inline-block;
    cursor: pointer;
  }

  @media all and (orientation: portrait) {
    div.close {
      font-size: 48px;
    }
  }

  div.settingsScreen div.mainInfo {
    width: 95%;
    margin: 30px auto 10px;
    text-align: center;
  }

  div.settingsScreen div.sectionTitle {
    font-size: 30px;
    color: #999;
    margin: 10px 0;
    text-align: left;
  }

  div.settingsScreen div.changeUsername {
    margin: 10px 5%;
    text-align: left;
  }

  div.settingsScreen div.changeUsername input {
    display: inline-block;
    width: 65%;
    font-size: 24px;
  }

  div.settingsScreen div.changeUsername button {
    display: inline-block;
    margin-left: 10px;
    width: 25%;
    font-size: 24px;
    cursor: pointer;
  }

  div.settingsScreen div.changeUsername button:disabled {
    cursor: auto;
  }

  div.settingsScreen div.changeAvatar {
    margin: 10px 5%;
    text-align: left;
  }

  div.settingsScreen div.changeAvatar div.avatarsList {
    width: 100%;
  }

  div.settingsScreen div.changeAvatar div.avatarsList div.avatar {
    display: inline-block;
    margin: 0 10px 10px 0;
    cursor: pointer;
  }

  @media (max-width: 640px) {
    div.settingsScreen div.sectionTitle {
      font-size: 24px;
    }

    div.settingsScreen div.avatar {
      width: 30vw;
      max-width: 40vh;
      height: 30vw;
      max-height: 40vh;
    }

    div.settingsScreen div.name {
      width: 30vw;
    }
  }

  div.settingsScreen div.changeAvatar div.avatarsList div.avatar.option {
    border: 7px solid rgba(220, 220, 220, 0.5);
  }
</style>

<div class="settings" bind:this={settingsIcons}>
  {#if show_profile}
    <div class="profile" on:click={profileIconClick}>
      <span class="icon-user" />
    </div>
  {/if}
</div>

<div class="settingsScreen" bind:this={settingsScreen}>
  <div class="close" on:click={settingsClose}>X</div>
  <div class="mainInfo">
    <div class="avatar">
      <img src={`/avatars/${$user_avatars[$user_id]}.jpg`} alt={`Avatar: ${$user_id}`} />
    </div>
    <div class="name">{$user_names[$user_id]}</div>
  </div>
  <div class="changeUsername">
    <div class="sectionTitle">Your Display Name</div>
    <input type="text" bind:value={nameInput} />
    {#if nameInput === nameInputDefault}
      <button disabled>Save</button>
    {:else}
      <button on:click={e => nameSave(nameInput)}>Save</button>
    {/if}
  </div>
  <div class="changeAvatar">
    <div class="sectionTitle">Select your avatar</div>
    <div class="avatarsList">
      {#each Array(108) as _, i}
        <div
          class={`avatar ${i + 1 === $user_avatars[$user_id] ? 'current' : 'option'}`}
          on:click={e => avatarSelect(i + 1)}>
          <img src={`/avatars/${i + 1}.jpg`} alt={`Avatar #${i + 1}`} />
        </div>
      {/each}
    </div>
  </div>
</div>
