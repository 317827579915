'use strict';

/**
 * Validate first move of game, i.e. can be any combo but must contain 3 of diamond.
 */

const getFiveCardType = require('./get-five-card-type');

module.exports = next => {
  const cards = Array.isArray(next) ? next : [next];
  if (cards.length === 1) {
    //
    // Singles
    //
    return true;
  } else if (cards.length === 2) {
    //
    // Pairs, must contain 3 of diamond
    //
    if (cards[0].order !== cards[1].order) {
      return false; // not pair
    }
    return true;
  } else if (cards.length === 3) {
    //
    // Triples, must contain 3 of diamond
    //
    if (cards[0].order !== cards[1].order || cards[0].order !== cards[2].order) {
      return false; // not triple
    }
    return true;
  } else if (cards.length === 5) {
    //
    // Five Card Set, must contain 3 of diamond
    //
    const fiveCardType = getFiveCardType(cards);
    if (!fiveCardType.type) {
      return false; // invalid set
    }
    return true;
  } else {
    return false; // invalid move
  }
};
