import { writable, readable } from 'svelte/store';
import { setCookie, getCookie } from './cookie';
import uuid from '../../../lib/uuid';
import log from '../../../lib/logger';

let _user_id = getCookie('user_id');
if (!_user_id) {
  setCookie('user_id', uuid(), '365');
  _user_id = getCookie('user_id');
  log.info(`New user: ${_user_id}`);
} else {
  log.info(`Existing user: ${_user_id}`);
}

// generate a one-time client id for just this browser window
// because you can open multiple windows as one user
const _client_id = uuid();

export const app_state = writable('initializing');

export const user_id = readable(null, function start(set) {
  // This apparently can get called more than once if using a getter to get it
  // Hence we do all the initialization at file scope global, then just use the result here
  set(_user_id);
  return function stop() {};
});

export const client_id = readable(null, function start(set) {
  set(_client_id);
  return function stop() {};
});

export const game_id = writable(null);

export const ws_conn = writable(null);

export const game_state = writable({});

export const dealt_hand = writable(null);

export const selected_cards = writable([]);

export const user_names = writable({});

export const user_avatars = writable({});
