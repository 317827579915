<script>
  export let type;

  export let card = {}; // unused
  card; // silence warning

  export let selected = false; // unused
  selected; // silence warning

  if (!type) {
    type = 1;
  }

  function getCardFilename(type) {
    return `back${type}.svg`;
  }
</script>

<style>
  img {
    height: 100%;
    border-radius: 4%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }
</style>

<img src={`/cardassets/${getCardFilename(type)}`} alt={`Card`} />
