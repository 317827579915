import { get } from 'svelte/store';
import { game_id, user_id, client_id, ws_conn } from './stores';
import log from '../../../lib/logger';

export default data => {
  if (typeof data === 'object') {
    const ws = get(ws_conn);
    return ws.send(
      JSON.stringify({ game_id: get(game_id), user_id: get(user_id), client_id: get(client_id), ...data })
    );
  } else {
    log.error(`message cannot be sent: not an object: ${data}`);
  }
};
