'use strict';

/**
 * Exports both the sortFn (for use when situations call for just calling .sort() on an array of cards)
 * Or a wrapper function around a provided cards array, that does the same thing.
 */

const powerLevel = require('./power-level');

const sortFn = function(a, b) {
  const orderA = a.order <= 1 ? a.order + 13 : a.order;
  const orderB = b.order <= 1 ? b.order + 13 : b.order;
  const comp = orderA - orderB;
  // sort by order (number) first
  if (comp !== 0) {
    return comp;
  }
  // for breakeven, sort by suit strength
  const suitStrengthA = powerLevel.Suit[a.suit];
  const suitStrengthB = powerLevel.Suit[b.suit];
  return suitStrengthA - suitStrengthB;
};

module.exports = {
  sortFn,
  sort: cards => cards.sort(sortFn)
};
