'use strict';

/**
 * Validate first move of game, i.e. can be any combo but must contain 3 of diamond.
 */

const getFiveCardType = require('./get-five-card-type');

module.exports = next => {
  const cards = Array.isArray(next) ? next : [next];
  if (cards.length === 1) {
    //
    // Singles, must be 3 of diamond
    //
    return cards[0].suit === 'diamond' && cards[0].order === 2;
  } else if (cards.length === 2) {
    //
    // Pairs, must contain 3 of diamond
    //
    if (cards[0].order !== cards[1].order) {
      return false; // not pair
    }
    if (cards[0].order !== 2) {
      return false; // not 3
    }
    // suit of either card must be diamond
    return cards[0].suit === 'diamond' || cards[1].suit === 'diamond';
  } else if (cards.length === 3) {
    //
    // Triples, must contain 3 of diamond
    //
    if (cards[0].order !== cards[1].order || cards[0].order !== cards[2].order) {
      return false; // not triple
    }
    if (cards[0].order !== 2) {
      return false; // not 3
    }
    // suit of either card must be diamond
    return cards[0].suit === 'diamond' || cards[1].suit === 'diamond' || cards[2].suit === 'diamond';
  } else if (cards.length === 5) {
    //
    // Five Card Set, must contain 3 of diamond
    //
    const fiveCardType = getFiveCardType(cards);
    if (!fiveCardType.type) {
      return false; // invalid set
    }
    // Look for 3 of diamond
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].order === 2 && cards[i].suit === 'diamond') {
        return true; // found 3 of diamond
      }
    }
    return false; // not 3 of diamond
  } else {
    return false; // invalid move
  }
};
