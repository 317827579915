'use strict';

const { ENV } = require('./constants');

if (typeof window === 'undefined') {
  // server: use pino
  const pino = require('pino');
  module.exports = pino({ timestamp: pino.stdTimeFunctions.isoTime });
} else {
  // client: no logging
  const logFn = (level, obj) => {
    if (ENV === 'dev') {
      console.log(level, obj);
    }
  };
  module.exports = {
    fatal: obj => logFn('fatal', obj),
    error: obj => logFn('error', obj),
    warn: obj => logFn('warn', obj),
    info: obj => logFn('info', obj),
    debug: obj => logFn('debug', obj),
    trace: obj => logFn('trace', obj)
  };
}
