<script>
  /**
   * Expected card object structure:
   * { suit: 'diamond', order: 0, number: 'A' }
   */
  export let card;
  export let selected;

  function getCardFilename(card) {
    const number = card.number === '10' ? 'T' : card.number;
    const suit = card.suit.substring(0, 1).toUpperCase();
    return `${number}${suit}.svg`;
  }
</script>

<style>
  img {
    height: 100%;
    border-radius: 4%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }
  img.selected {
    opacity: 0.7;
  }
</style>

<img
  src={`/cardassets/${getCardFilename(card)}`}
  class={selected ? 'selected' : ''}
  alt={`${card.number} of ${card.suit}`} />
