'use strict';

const _ = require('lodash');
const Player = require('./player');

class GameState {
  constructor(game_id, players) {
    if (!Array.isArray(players)) {
      players = [];
    }
    if (players.length > 4) {
      players = players.slice(0, 4);
    }
    this.game_id = game_id;
    this.players = players;
    this.current_turn = null;
    this.last_played = null;
    this.played_pile = [[]]; // first played pile is empty set of cards
    this.game_ended = false;
  }

  static fromJson(json) {
    const obj = JSON.parse(json);
    const res = new GameState(obj.game_id);
    for (const playerObj of obj.players) {
      res.addPlayer(new Player(playerObj.user_id, playerObj.hand));
    }
    Object.assign(res, _.pick(obj, 'current_turn', 'last_played', 'played_pile', 'game_ended'));
    return res;
  }

  addPlayer(player) {
    this.players.push(player);
  }

  blindize(user_id, shortenFn) {
    const result = _.cloneDeep(this);
    for (const player of result.players) {
      if (player.user_id !== user_id) {
        player.user_id = shortenFn(player.user_id);
        player.hand = new Array(player.hand.length);
      }
    }
    return result;
  }
}

module.exports = GameState;
