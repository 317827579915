'use strict';

const log = require('./logger');
const newDeck = require('./new-deck');
// const { sortFn } = require('./sort-hand');

const shuffle = a => {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

module.exports = () => {
  const deck = newDeck();
  const shuffled = shuffle(deck);

  log.info('Dealing deck to 4 players');
  // return hands of 4 players in array
  return [shuffled.slice(0, 13), shuffled.slice(13, 26), shuffled.slice(26, 39), shuffled.slice(39, 52)];
};
