<script>
  import Hand from './hand.svelte';
  import PlayButton from './play-button.svelte';
  import { sort } from '../../../lib/sort-hand';

  export let is_online;
  export let is_player = true; // only used by online mode, set default value so it won't error if not provided
  export let cards;
  export let shaped;
  export let is_my_turn;
  export let is_first_turn;
  export let is_everyone_passed;
  export let onclick;
</script>

<style>
  div.button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
</style>

{#if !is_online}
  <Hand cards={sort(cards)} {shaped} selectable={is_my_turn} face={is_my_turn ? 'front' : 'back'} />
  {#if is_my_turn}
    <div class="button">
      <PlayButton {is_first_turn} {is_everyone_passed} {onclick} />
    </div>
  {/if}
{:else}
  {#if is_player}
    <Hand cards={sort(cards)} {shaped} selectable={is_my_turn} face={'front'} />
    {#if is_my_turn}
      <div class="button">
        <PlayButton {is_first_turn} {is_everyone_passed} {onclick} />
      </div>
    {/if}
  {:else}
    <Hand {cards} {shaped} selectable={false} face={'back'} />
  {/if}
{/if}
