'use strict';

/**
 * Given the current card(s) on table on current turn, validate if the attempted
 * card(s) is a valid next move.
 */

const { FiveCard, FiveCardBreakEven, Suit } = require('./power-level');
const getFiveCardType = require('./get-five-card-type');

module.exports = (current, next) => {
  const cardsCurrent = Array.isArray(current) ? current : [current];
  const cardsNext = Array.isArray(next) ? next : [next];

  // Must be same number of cards
  if (cardsNext.length !== cardsCurrent.length) {
    return false;
  }

  if (cardsNext.length === 1) {
    //
    // Singles
    //
    const orderNext = cardsNext[0].order <= 1 ? cardsNext[0].order + 13 : cardsNext[0].order;
    const orderCurrent = cardsCurrent[0].order <= 1 ? cardsCurrent[0].order + 13 : cardsCurrent[0].order;
    if (orderNext > orderCurrent) {
      return true; // bigger single
    } else if (orderNext < orderCurrent) {
      return false; // smaller single
    } else {
      // same number, use suit
      return Suit[cardsNext[0].suit] > Suit[cardsCurrent[0].suit];
    }
  } else if (cardsNext.length === 2) {
    //
    // Pairs
    //
    if (cardsNext[0].order !== cardsNext[1].order) {
      return false; // not a pair
    }
    const orderNext = cardsNext[0].order <= 1 ? cardsNext[0].order + 13 : cardsNext[0].order;
    const orderCurrent = cardsCurrent[0].order <= 1 ? cardsCurrent[0].order + 13 : cardsCurrent[0].order;
    if (orderNext > orderCurrent) {
      return true; // bigger pair
    } else if (orderNext < orderCurrent) {
      return false; // smaller pair
    }
    // Pick the bigger suit of each pair
    const suitCurrent = Math.max(Suit[cardsCurrent[0].suit], Suit[cardsCurrent[1].suit]);
    const suitNext = Math.max(Suit[cardsNext[0].suit], Suit[cardsNext[1].suit]);
    return suitNext > suitCurrent;
  } else if (cardsNext.length === 3) {
    //
    // Triples
    //
    if (cardsNext[0].order !== cardsNext[1].order || cardsNext[0].order !== cardsNext[2].order) {
      return false; // not a triple
    }
    const orderNext = cardsNext[0].order <= 1 ? cardsNext[0].order + 13 : cardsNext[0].order;
    const orderCurrent = cardsCurrent[0].order <= 1 ? cardsCurrent[0].order + 13 : cardsCurrent[0].order;
    return orderNext > orderCurrent; // bigger triple
  } else if (cardsNext.length === 5) {
    //
    // Five Card Sets
    //
    const typeNext = getFiveCardType(cardsNext);
    if (!typeNext.type) {
      return false; // invalid set
    }
    const typeCurrent = getFiveCardType(cardsCurrent);
    if (FiveCard[typeNext.type] > FiveCard[typeCurrent.type]) {
      return true; // bigger five card type
    }
    // Breakevens
    const breakEvenList = FiveCardBreakEven[typeNext.type];
    for (let i = 0; i < breakEvenList.length; i++) {
      if (breakEvenList[i] === 'order') {
        if (typeNext.order > typeCurrent.order) {
          return true;
        } else if (typeNext.order < typeCurrent.order) {
          return false;
        } else {
          continue;
        }
      }
      if (breakEvenList[i] === 'suit') {
        return Suit[typeNext.suit] > Suit[typeCurrent.suit];
      }
    }
    return false; // should not get here
  } else {
    // Other number of cards are not valid
    return false;
  }
};
