import { WEBSOCKET_URL } from '../../../lib/constants';
import { get } from 'svelte/store';
import { ws_conn, app_state, game_state, dealt_hand, user_names, user_avatars } from './stores';
import ws_send from './ws-send';
import log from '../../../lib/logger';

export const controller = () => {
  const ws = new WebSocket(WEBSOCKET_URL);

  ws.onopen = function(e) {
    ws_send({ op: 'ready' });
    log.info('Listening to websocket...');
  };

  ws.onmessage = function(e) {
    if (e && e.data) {
      var wsMsg = e.data;
      let response;
      try {
        response = JSON.parse(wsMsg);
      } catch (e) {
        // error
        log.error(`cannot parse ws response: ${wsMsg}`);
        return;
      }
      log.info(response);
      if (response.ev) {
        if (response.ev === 'ping') {
          ws_send({ op: 'pong' });
        } else if (response.ev === 'ready') {
          app_state.set('ready');
        } else if (response.ev === 'deal-deck') {
          // This kicks off the deal deck animation. By the end of animation, it should switch app_state
          // into game.
          dealt_hand.set(response.data);
          app_state.set('deal');
        } else if (response.ev === 'broadcast') {
          log.info(response.data);
          game_state.set(response.data.game_state);
          if (get(app_state) !== 'deal') {
            app_state.set(response.data.app_state); // game or waiting
          }
          user_names.set(response.data.user_names);
          user_avatars.set(response.data.user_avatars);
        } else {
          log.info(`unknown ev ${response.ev}`);
        }
      } else {
        log.info('no ev');
      }
    }
  };

  ws.onclose = function(e) {
    // handle mobile switch away disconnect
    window.location.reload(true);
  };

  if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
    window.lastAliveTick = new Date().getTime();
    function myTimer() {
      var curAliveTick = new Date().getTime();
      if (curAliveTick - window.lastAliveTick > 5000) {
        window.location.reload(true);
      }
      window.lastAliveTick = curAliveTick;
    }
    window.addEventListener('pagehide', function() {
      window.lastAliveTick = 0;
    });
    window.addEventListener('unload', function() {
      window.lastAliveTick = 0;
    });
    setInterval(myTimer, 1000);
  }

  ws_conn.set(ws);
};
