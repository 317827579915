'use strict';

const Player = require('../classes/player');
const GameState = require('../classes/game-state');
const dealDeck = require('../deal-deck');

/**
 * Start local game
 * - Create player objects off of user_ids
 * - Create game state object off of players and deal deck
 * - Return game state object
 */
module.exports = function(user_ids) {
  if (!Array.isArray(user_ids) || user_ids.length !== 4) {
    return null;
  }

  // Deal deck first
  const hands = dealDeck();

  // Create players off of ids, give hands
  const players = [];
  for (let i = 0; i < 4; i++) {
    players.push(new Player(user_ids[i], hands[i]));
  }

  // Create game
  const game_state = new GameState('local', players);

  // Check for 3 of diamond and determine first turn
  for (let i = 0; i < 4; i++) {
    const player = players[i];
    for (let j = 0; j < player.hand.length; j++) {
      if (player.hand[j].suit === 'diamond' && player.hand[j].order === 2) {
        game_state.current_turn = i;
        break;
      }
    }
    if (game_state.current_turn !== null) {
      break;
    }
  }

  return game_state;
};
