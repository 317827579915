<script>
  import { selected_cards } from '../utils/stores';

  export let is_first_turn;
  export let is_everyone_passed;
  export let onclick;
</script>

<style>
  button {
    cursor: pointer;
    font-size: 2.2vw;
    font-weight: bold;
    background-color: #ccc;
    color: #999;
    padding: 5px 20px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }

  @media all and (orientation: portrait) {
    button {
      font-size: 20px;
    }
  }

  button.play {
    background-color: #ff9;
    color: #090;
    border: 3px solid #090;
  }
</style>

<button class={$selected_cards.length ? 'play' : ''} on:click|preventDefault={onclick}>
  {#if $selected_cards.length}
    Play selected
  {:else if is_first_turn}You start!{:else if is_everyone_passed}Everyone passed!{:else}Pass turn{/if}
</button>
