<script>
  import { tick } from 'svelte';

  export let value = null;

  let valueCopy = null;
  let areaDom;
  let checkmark;

  async function copy() {
    valueCopy = value;
    await tick();
    areaDom.focus();
    areaDom.select();
    let message = 'Copying text was successful';
    try {
      const successful = document.execCommand('copy');
      if (!successful) {
        message = 'Copying text was unsuccessful';
      }
      checkmark.classList.add('done');
      setTimeout(() => checkmark.classList.remove('done'), 4000);
    } catch (err) {
      message = 'Oops, unable to copy';
    }

    valueCopy = null;
  }
</script>

<style>
  textarea {
    position: fixed;
    top: 0;
    left: 0;
    width: 2em;
    height: 2em;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
  }

  div.icon {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 36px;
  }

  svg.octicon {
    cursor: pointer;
  }

  svg.check {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  :global(svg.check.done) {
    display: block;
  }
</style>

<!--
  From: https://gist.github.com/stalkerg/2b160b6aca02268506eec23b13c714f9
-->
{#if valueCopy != null}
  <textarea bind:this={areaDom}>{valueCopy}</textarea>
{/if}
<div class="icon">
  <svg
    on:click={copy}
    title="Copy to clipboard"
    class="octicon octicon-clippy"
    viewBox="0 0 14 16"
    version="1.1"
    style={`width: 100%; height: 100%;`}
    aria-hidden="true">
    <path
      fill-rule="evenodd"
      d="M2 13h4v1H2v-1zm5-6H2v1h5V7zm2 3V8l-3 3 3 3v-2h5v-2H9zM4.5 9H2v1h2.5V9zM2 12h2.5v-1H2v1zm9
      1h1v2c-.02.28-.11.52-.3.7-.19.18-.42.28-.7.3H1c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1h3c0-1.11.89-2 2-2 1.11 0 2 .89 2
      2h3c.55 0 1 .45 1 1v5h-1V6H1v9h10v-2zM2 5h8c0-.55-.45-1-1-1H8c-.55 0-1-.45-1-1s-.45-1-1-1-1 .45-1 1-.45 1-1
      1H3c-.55 0-1 .45-1 1z" />
  </svg>
  <svg class="check" style={`width: 130%; height: 125%;`} viewBox="0 0 24 24" bind:this={checkmark}>
    <path
      stroke="#0f0"
      d="M0 11c2.761.575 6.312 1.688 9 3.438 3.157-4.23 8.828-8.187 15-11.438-5.861 5.775-10.711 12.328-14
      18.917-2.651-3.766-5.547-7.271-10-10.917z" />
  </svg>
</div>
