'use strict';

class Player {
  constructor(user_id, hand) {
    if (!Array.isArray(hand)) {
      hand = [];
    }
    this.user_id = user_id;
    this.hand = hand;
  }

  deal(hand) {
    this.hand = hand;
  }
}

module.exports = Player;
