<script>
  import PlayerHand from './player-hand.svelte';
  import PlayedPile from './played-pile.svelte';

  export let game_state;
  export let render_index_map;
  export let user_names;
  export let user_avatars;
  export let is_online;
  export let on_play_button_click;

  // Works with the passed in render_index_map above.
  // Basically, this here is the order we're rendering the divs in (so z-index and stuff works correctly).
  // Now, depending on the passed in render_index_map, you can render arbitrary nth player from game_state.players
  // in any order you wish.
  let playerClasses = ['opponentRightBox', 'opponentTopBox', 'opponentLeftBox', 'playerBox'];

  // Turn off shaped hand for now
  let shaped = false;
</script>

<style>
  div.avatar {
    border: 5px solid rgba(220, 220, 220, 0.5);
  }

  div.avatar.isTurn {
    border: 7px solid rgb(220, 220, 30);
  }

  @media all and (orientation: landscape) {
    div.playedPile {
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-25%, -50%);
      width: 25vw;
      height: 25vh;
    }
  }

  @media all and (orientation: portrait) {
    div.playedPile {
      position: absolute;
      z-index: 0;
      top: 50vh;
      left: 50%;
      transform: translate(-20%, 10%);
      width: 80vw;
      height: 20vh;
    }
  }

  @media (min-width: 640px) and (orientation: portrait) {
    div.playedPile {
      transform: translate(-25%, 30%);
      width: 50vw;
    }
  }

  div.gameOver {
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: 0 auto;
    font-size: 72px;
    color: #f00;
  }
</style>

<div class="playedPile">
  <PlayedPile played_pile={game_state.played_pile} />
</div>

{#each [0, 1, 2, 3] as index}
  <div class={playerClasses[index]}>
    <div class="infoDiv">
      <div class={`avatar ${game_state.current_turn === render_index_map[index] ? 'isTurn' : ''}`}>
        <img
          src={`/avatars/${user_avatars[game_state.players[render_index_map[index]].user_id]}.jpg`}
          alt={`Avatar: ${game_state.players[render_index_map[index]].user_id}`} />
      </div>
      <div class="name">{user_names[game_state.players[render_index_map[index]].user_id]}</div>
    </div>
    <div class="handDiv">
      <PlayerHand
        {is_online}
        is_player={is_online ? index === 3 : true}
        cards={game_state.players[render_index_map[index]].hand}
        {shaped}
        is_my_turn={game_state.current_turn === render_index_map[index]}
        is_first_turn={game_state.last_played === null}
        is_everyone_passed={game_state.last_played === render_index_map[index]}
        onclick={() => on_play_button_click(game_state.players[render_index_map[index]].user_id)} />
    </div>
  </div>
{/each}

{#if game_state.game_ended}
  <div class="gameOver">Game Over!</div>
{/if}
