'use strict';

/**
 * Not the best but passable uuid v4 generator. From:
 * https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 */

module.exports = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
