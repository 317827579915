<script>
  import { onMount } from 'svelte';
  import Hand from '../components/hand.svelte';
  import log from '../../../lib/logger';
  import { app_state, game_id, user_id, game_state, dealt_hand, selected_cards } from '../utils/stores';
  import { user_names, user_avatars } from '../utils/stores';
  import { controller } from '../utils/client-controller';
  import ws_send from '../utils/ws-send';
  import GameStage from '../components/game-stage.svelte';
  import DealAnimation from '../components/deal-animation.svelte';
  import Clipboard from '../components/clipboard.svelte';
  import SettingsHud from '../components/settings-hud.svelte';

  // Define the component prop "params" to use params
  export let params = {};

  /**
   * Player Takes Turn function
   */
  function playerTakesTurn(user_id) {
    ws_send({ op: 'take_turn', cards: $selected_cards });
    selected_cards.set([]);
  }

  /**
   * Dealing Done - kick off game app_state
   */
  let dealingDone = function() {
    app_state.set('game');
  };

  /**
   * Copy Link stuff
   */
  let copylink;
  function onCopylinkClick() {
    copylink.value = location.href;
    copylink.select();
  }

  /**
   * Waiting room avatar click
   */
  let settingsHud;
  function openSettings() {
    // open HUD icon settings
    settingsHud.profileIconClick();
  }

  /**
   * Determine render_index_map for GameStage component
   */
  let playerIndex;
  let render_index_map = [3, 2, 1, 0];
  let deal_order = []; // DealAnimation
  let deal_names = {}; // DealAnimation
  let deal_avatars = {}; // DealAnimation
  $: {
    if ($game_state && $game_state.players) {
      playerIndex = $game_state.players.findIndex(x => x.user_id === $user_id);
      if (playerIndex === 1) {
        render_index_map = [0, 3, 2, 1];
      } else if (playerIndex === 2) {
        render_index_map = [1, 0, 3, 2];
      } else if (playerIndex === 3) {
        render_index_map = [2, 1, 0, 3];
      }

      // Here, we run logic to deduce DealAnimation's players order by user_id,
      // and pass along the relevant user names and avatars to DealAnimation.
      deal_order = [];
      for (const i of render_index_map) {
        if ($game_state.players[i]) {
          deal_order.push($game_state.players[i].user_id);
          deal_names[$game_state.players[i].user_id] = $user_names[$game_state.players[i].user_id];
          deal_avatars[$game_state.players[i].user_id] = $user_avatars[$game_state.players[i].user_id];
        }
      }
    }
  }

  /**
   * onMount - kick off websocket controller
   */
  onMount(() => {
    document.getElementsByTagName('body')[0].classList.add('game_online');
    $app_state = 'connecting';
    $game_id = params.id;
    controller();

    return () => {
      // unmount function
      document.getElementsByTagName('body')[0].classList.remove('game_online');
    };
  });
</script>

<style>
  div.state {
    width: 75vw;
    margin: 10vh auto;
    font-size: 3vw;
    text-align: center;
    padding: 5vh;
    color: #444;
    background-color: rgba(220, 220, 220, 0.75);
    border-radius: 10px;
    border: 1px solid rgba(30, 30, 30, 0.25);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }

  input.copylink {
    font-size: 1.8vw;
    padding: 1.5vh;
    width: 50vw;
    background-color: rgba(250, 250, 250, 0.5);
    border-radius: 3px;
    border: 1px solid rgba(90, 90, 90, 0.5);
  }

  div.waitingPlayer {
    display: inline-block;
    margin: 0 20px;
  }

  div.waitingPlayer div.avatar {
    cursor: pointer;
  }

  div.waitingPlayer div.avatar.others {
    cursor: auto;
    border: 7px solid rgba(220, 220, 220, 0.5);
  }

  div.waitingPlayer div.name {
    font-size: 30px;
  }

  /**
   * Minimum font size for smaller screens
   */
  @media (max-width: 640px) {
    div.state {
      font-size: 24px;
    }

    input.copylink {
      font-size: 18px;
    }

    div.waitingPlayer {
      margin: 0 10px;
    }

    div.waitingPlayer div.avatar {
      width: 25vw;
      height: 25vw;
    }

    div.waitingPlayer div.name {
      width: 25vw;
      font-size: 20px;
    }
  }
</style>

<main>
  {#if $app_state === 'game'}
    <div>
      <GameStage
        game_state={$game_state}
        {render_index_map}
        user_names={$user_names}
        user_avatars={$user_avatars}
        is_online={true}
        on_play_button_click={playerTakesTurn} />
    </div>
  {:else if $app_state === 'deal'}
    <div>
      <DealAnimation
        dealt_hand={$dealt_hand}
        speed={200}
        onDone={dealingDone}
        {deal_order}
        {deal_names}
        {deal_avatars} />
    </div>
  {:else if $app_state === 'waiting'}
    <div class="state">
      <p>
        Send this game link to friends!
        <br />
        <input
          type="text"
          class="copylink"
          value={location.href}
          bind:this={copylink}
          on:click={onCopylinkClick}
          readonly />
        <Clipboard value={location.href} />
      </p>
      {#if $game_state.players && $game_state.players.length}
        <h3>Joined players</h3>
        {#each $game_state.players as player}
          <div class="waitingPlayer">
            <div
              class={`avatar ${player.user_id !== $user_id ? 'others' : ''}`}
              on:click={e => {
                if (player.user_id === $user_id) {
                  openSettings();
                }
              }}>
              <img src={`/avatars/${$user_avatars[player.user_id]}.jpg`} alt={`Avatar: ${player.user_id}`} />
            </div>
            <div class="name">{$user_names[player.user_id]}</div>
          </div>
        {/each}
        <div class="waitingMore">Waiting for {4 - $game_state.players.length} more...</div>
      {/if}
    </div>
  {:else if $app_state === 'ready'}
    <div class="state">Ready...</div>
  {:else if $app_state === 'connecting'}
    <div class="state">Connecting...</div>
  {:else if $app_state === 'error'}
    <div class="state">Error! Reload?</div>
  {:else}
    <div class="state">Initializing...</div>
  {/if}
</main>

<SettingsHud bind:this={settingsHud} />
