<script>
  import { onMount } from 'svelte';
  import Hand from '../components/hand.svelte';
  import { app_state, game_state, dealt_hand, selected_cards, user_names, user_avatars } from '../utils/stores';
  import DealAnimation from '../components/deal-animation.svelte';
  import GameStage from '../components/game-stage.svelte';
  import startLocal from '../../../lib/game-states/start-local';
  import takeTurn from '../../../lib/game-states/take-turn';
  import SettingsHud from '../components/settings-hud.svelte';

  function gameStart() {
    // Start local game with players seeded and state started
    game_state.set(startLocal(['A', 'B', 'C', 'D']));
    // Set dealt_hand to kick off animation
    dealt_hand.set($game_state.players[0].hand);
    // Kick off animation
    app_state.set('deal');
    // Set generic user names
    user_names.set({ A: 'Player 1', B: 'Player 2', C: 'Player 3', D: 'Player 4' });
    // Set randomized avatars
    const avs = new Array(108)
      .fill(undefined)
      .map((v, i) => i + 1)
      .sort(() => Math.random() - 0.5);
    user_avatars.set({ A: avs[0], B: avs[1], C: avs[2], D: avs[3] });
  }

  function playerTakesTurn(user_id) {
    game_state.set(takeTurn($game_state, user_id, $selected_cards));
    selected_cards.set([]);
  }

  let dealingDone = function() {
    app_state.set('game');
  };

  const render_index_map = [3, 2, 1, 0];

  // DealAnimation uses these to look up names and avatars in order of rendering
  const deal_order = ['D', 'C', 'B', 'A'];

  onMount(() => {
    $app_state = 'ready';
    document.getElementsByTagName('body')[0].classList.add('game_local');
    gameStart();

    return () => {
      // unmount function
      document.getElementsByTagName('body')[0].classList.remove('game_local');
    };
  });
</script>

<style>

</style>

<main>
  {#if $app_state === 'game'}
    <div>
      <GameStage
        game_state={$game_state}
        {render_index_map}
        user_names={$user_names}
        user_avatars={$user_avatars}
        is_online={false}
        on_play_button_click={playerTakesTurn} />
    </div>
  {:else if $app_state === 'deal'}
    <div>
      <DealAnimation
        dealt_hand={$dealt_hand}
        speed={200}
        onDone={dealingDone}
        {deal_order}
        deal_names={$user_names}
        deal_avatars={$user_avatars} />
    </div>
  {:else}
    <div>Loading...</div>
  {/if}
</main>

<SettingsHud show_profile={false} />
