'use strict';

let envVars;

if (typeof window === 'undefined') {
  // Backend
  envVars = process.env;
} else {
  // Frontend
  try {
    envVars = require('../frontend-env');
  } catch (e) {
    envVars = {};
  }
}

module.exports = {
  // Client needs
  WEBSOCKET_URL: envVars.WEBSOCKET_URL,

  // Client & Server need
  ENV: envVars.ENV,

  // Server needs
  PORT: envVars.PORT,
  ADDRESS: envVars.ADDRESS || '0.0.0.0',
  REDIS_URL: envVars.REDIS_URL
};
