<script>
  import { onMount } from 'svelte';
  import { tweened } from 'svelte/motion';
  import { cubicOut } from 'svelte/easing';
  import Hand from './hand.svelte';
  import CardBack from './card-back.svelte';
  import { sort } from '../../../lib/sort-hand';
  import log from '../../../lib/logger';

  export let dealt_hand;
  export let speed;
  export let onDone;
  export let deal_order;
  export let deal_names;
  export let deal_avatars;

  // Different from GameStage, this doesn't work with a render_index_map.
  // The player user_ids names and avatars are passed in in the exact order as 3,2,1,0 like below.
  // The `dealt_card` array is only the player's cards.
  // Basically, it's only a rough simulation of what happens in GameStage component.
  let playerClasses = ['opponentRightBox', 'opponentTopBox', 'opponentLeftBox', 'playerBox'];

  let shaped = false;
  let supplyPile;
  let supplyCard;

  const resetSupply = () =>
    tweened(50, {
      duration: speed,
      easing: cubicOut
    });
  let topPct = resetSupply();
  let leftPct = resetSupply();

  let topPctByPlayer = [90, null, 10, null];
  let leftPctByPlayer = [null, 10, null, 90];
  let cards = [[], [], [], []];

  const orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
  if (orientation === 'portrait-secondary' || orientation === 'portrait-primary') {
    topPctByPlayer = [85, 5, 20, 35];
    leftPctByPlayer = [null, null, null, null];
  }

  onMount(async () => {
    log.info('dealt hand');
    log.info(dealt_hand);

    // start animation

    function dealCard(topPctSet, leftPctSet) {
      if (topPctSet !== null) {
        return topPct.set(topPctSet); // returns Promise
      } else {
        return leftPct.set(leftPctSet); // returns Promise
      }
    }

    async function dealRound(roundNum) {
      for (let playerNum = 0; playerNum < topPctByPlayer.length; playerNum++) {
        await dealCard(topPctByPlayer[playerNum], leftPctByPlayer[playerNum]);

        cards[playerNum].push(playerNum === 0 ? dealt_hand[roundNum] : {});
        cards = cards; // svelte reactivity is assignment based

        topPct = resetSupply();
        leftPct = resetSupply();

        if (roundNum === dealt_hand.length - 1) {
          if (playerNum === topPctByPlayer.length - 1) {
            // next deal will be last card
            supplyPile.remove(); // DOM node remove
          }
        }
      }
    }

    for (let roundNum = 0; roundNum < dealt_hand.length; roundNum++) {
      await dealRound(roundNum);
    }
    supplyCard.remove(); // DOM node remove
    setTimeout(() => {
      cards = sort(cards);
      shaped = true;
      if (onDone) {
        onDone();
      }
    }, 600);

    return () => {
      // unmount function
    };
  });
</script>

<style>
  @media all and (orientation: landscape) {
    div.supplyPile {
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8vw;
      height: 14vh;
    }

    div.supplyCard {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8vw;
      height: 14vh;
    }
  }

  @media all and (orientation: portrait) {
    div.supplyPile {
      position: absolute;
      z-index: 0;
      top: 50vh;
      left: 50%;
      transform: translate(-25%, 30%);
      width: 15vw;
      height: 14vh;
    }

    div.supplyCard {
      position: absolute;
      z-index: 1;
      top: 50vh;
      left: 50%;
      transform: translate(-25%, 30%);
      width: 15vw;
      height: 14vh;
    }
  }

  @media (min-width: 640px) and (orientation: portrait) {
    div.supplyPile {
      transform: translate(-25%, 50%);
      width: 10vw;
    }

    div.supplyCard {
      transform: translate(-25%, 50%);
      width: 10vw;
    }
  }
</style>

<div class="supplyPile" bind:this={supplyPile}>
  <CardBack />
</div>

<div class="supplyCard" bind:this={supplyCard} style="top: {$topPct}%; left: {$leftPct}%;">
  <CardBack />
</div>

{#each [0, 1, 2, 3] as index}
  <div class={playerClasses[index]}>
    <div class="infoDiv">
      <div class="avatar">
        <img src={`/avatars/${deal_avatars[deal_order[index]]}.jpg`} alt={`Avatar: ${deal_order[index]}`} />
      </div>
      <div class="name">{deal_names[deal_order[index]]}</div>
    </div>
    <div class="handDiv">
      <Hand face={index === 3 ? 'front' : 'back'} cards={cards[3 - index]} {shaped} />
    </div>
  </div>
{/each}
