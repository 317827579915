<script>
  import Card from './card.svelte';
  import hash from 'string-hash';

  export let played_pile;

  function getPseudoRandomPos(card) {
    if (!card) {
      return '';
    }
    const rand = hash(JSON.stringify(card));
    const r1 = ((rand % 100) / 99) * 2 - 1;
    const r2 = ((rand % 1000) / 999) * 2 - 1;
    const r3 = ((rand % 10000) / 9999) * 10 - 5;
    return `left: ${r1}%; top: ${r2}%; transform: rotate(${r3}deg);`;
  }

  function getRotateCss(totalCards, cardPos) {
    let middlePos;
    if (totalCards % 2 === 1) {
      // odd number, middle card is straight
      middlePos = Math.floor(totalCards / 2);
    } else {
      // even number, middle-1 and middle+1 are closest to straight
      middlePos = totalCards / 2 - 0.5;
    }
    // max rotate is 5 degrees
    const rotateDeg = 5 * ((cardPos - middlePos) / middlePos);
    const topPos = Math.abs(rotateDeg) * 2;
    // Figure out a way to use percentage for this top px later?
    return `transform: rotate(${rotateDeg}deg); top: ${topPos}px;`;
  }
</script>

<style>
  div.hand {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  div.card {
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
  }
</style>

{#each played_pile as cards, i}
  <div class="hand" style={`z-index: ${100 - i}; ${getPseudoRandomPos(cards[0])}`}>
    {#each cards as card, i}
      <div class="card" style={`left: ${i * 4.86 - (4.86 * cards.length) / 2}%; ${getRotateCss(cards.length, i)}`}>
        <Card {card} selected={false} />
      </div>
    {/each}
  </div>
{/each}
