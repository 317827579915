<script>
  import Router from 'svelte-spa-router';
  import Home from './routes/home.svelte';
  import GameOnline from './routes/game-online.svelte';
  import GameLocal from './routes/game-local.svelte';
  import NotFound from './routes/not-found.svelte';
  import { app_state } from './utils/stores';

  const routes = {
    // Exact path
    '/': Home,
    // Using named parameters, with last being optional
    '/game/:id': GameOnline,
    // Local game - mostly for testing and implementation
    '/gamelocal': GameLocal,
    // Catch-all
    // This is optional, but if present it must be the last
    '*': NotFound
  };

  // let bgmusic = '/music/intro.mp3';

  // $: {
  //   if ($app_state === 'game' || $app_state === 'deal') {
  //     bgmusic = '/music/game.mp3';
  //   } else {
  //     bgmusic = '/music/intro.mp3';
  //   }
  // }
</script>

<!-- audio src={bgmusic} autoplay loop / -->

<Router {routes} />
