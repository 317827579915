'use strict';

const validateFirstMove = require('../validate-first-move');
const validateFreeMove = require('../validate-free-move');
const validateNextMove = require('../validate-next-move');
const { sort } = require('../sort-hand');

/**
 * Take Turn
 * - Given: GameState, user_id, cards being played
 * - Validate it is client's turn
 * - Validate if client owns those cards
 * - Validate if cards played are valid
 * - If all valid, update gameState current_turn, played_pile, update player's hand
 * - After updates, check if game ended, and update game_ended if so
 */
module.exports = function(game_state, user_id, cards) {
  // make sure only current turn player can take turn
  if (game_state.players[game_state.current_turn].user_id !== user_id) {
    return game_state;
  }

  // Check if passing turn
  if (!cards.length) {
    if (game_state.last_played === null || game_state.players[game_state.last_played].user_id === user_id) {
      // if everyone passed and it's back to your turn, you can't pass
      return game_state;
    }
    game_state.current_turn++;
    if (game_state.current_turn >= game_state.players.length) {
      game_state.current_turn = 0;
    }
    // last_played stays the same when passed
    return game_state;
  }

  // validate client owns those cards
  const { hand } = game_state.players[game_state.current_turn];
  const handIndices = [];
  for (const card of cards) {
    let found = false;
    for (let i = 0; i < hand.length; i++) {
      if (card.suit === hand[i].suit && card.order === hand[i].order) {
        handIndices.push(i); // remember the index of which the card is found
        found = true;
        break;
      }
    }
    if (!found) {
      // move contains cards the player doesn't have
      return game_state;
    }
  }

  // validate move
  if (game_state.played_pile.length === 1) {
    // validate if first move
    if (!validateFirstMove(cards)) {
      return game_state;
    }
  } else if (game_state.players[game_state.last_played].user_id === user_id) {
    // if everyone passed and it's back to you, free move
    if (!validateFreeMove(cards)) {
      return game_state;
    }
  } else {
    // validate if next move
    if (!validateNextMove(game_state.played_pile[0], cards)) {
      return game_state;
    }
  }

  // update game state...

  // update the player's hand
  for (let i = hand.length - 1; i >= 0; i--) {
    // use previously found hand indices to remove
    // loop from end to start, so removal of element doesn't affect loop flow
    if (handIndices.includes(i)) {
      game_state.players[game_state.current_turn].hand.splice(i, 1);
    }
  }

  // update played pile
  game_state.played_pile.unshift(sort(cards));

  // update if game ended
  if (game_state.players[game_state.current_turn].hand.length <= 0) {
    game_state.game_ended = true;
  }

  // update whose turn it is
  game_state.last_played = game_state.current_turn;
  if (!game_state.game_ended) {
    game_state.current_turn++;
    if (game_state.current_turn >= game_state.players.length) {
      game_state.current_turn = 0;
    }
  } else {
    game_state.current_turn = null;
  }

  return game_state;
};
