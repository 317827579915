<script>
  import Card from './card.svelte';
  import CardBack from './card-back.svelte';
  import { selected_cards } from '../utils/stores';
  import log from '../../../lib/logger';

  export let cards;
  export let shaped = true;
  export let face = 'front';
  export let centered = false;
  export let selectable = false;

  const component = {
    front: Card,
    back: CardBack
  };

  function getRotateCss(totalCards, cardPos) {
    let middlePos;
    if (totalCards % 2 === 1) {
      // odd number, middle card is straight
      middlePos = Math.floor(totalCards / 2);
    } else {
      // even number, middle-1 and middle+1 are closest to straight
      middlePos = totalCards / 2 - 0.5;
    }
    // max rotate is 20 degrees
    const rotateDeg = shaped ? 20 * ((cardPos - middlePos) / middlePos) : 0;
    const topPos = Math.abs(rotateDeg) * 2;
    // Figure out a way to use percentage for this top px later?
    return `transform: rotate(${rotateDeg}deg); top: ${topPos}px;`;
  }

  const orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
  let cardPosMultiplier;
  if (orientation === 'portrait-secondary' || orientation === 'portrait-primary') {
    cardPosMultiplier = 5.5;
  } else {
    cardPosMultiplier = 4.6; // was 4.86
  }

  function getCardPos(totalCards, cardPos) {
    if (!centered) {
      return cardPos * cardPosMultiplier;
    } else {
      const totalWidthPct = 27.8 + (totalCards - 1) * 4.86;
      return 50 - totalWidthPct / 2 + cardPos * 4.86;
    }
  }

  function cardClicked(event, card) {
    if (!selectable) {
      return; // do nothing
    }
    log.info('card clicked');
    log.info(card);
    const index = $selected_cards.indexOf(card);
    if (index !== -1) {
      log.info('removed from selected');
      $selected_cards.splice(index, 1);
    } else {
      log.info('added to selected');
      $selected_cards.push(card);
    }
    selected_cards.set($selected_cards); // reactive set
  }
</script>

<style>
  div.hand {
    position: relative;
    width: 100%;
    height: 100%;
  }
  div.card {
    height: 100%;
    display: inline-block;
    position: absolute;
    top: 0;
  }
  div.selectable {
    cursor: pointer;
  }
  div.selected {
    background-color: #ff0;
  }
</style>

<div class="hand">
  {#each cards as card, i}
    <div
      on:click|stopPropagation={e => cardClicked(e, card)}
      class={`card ${selectable ? 'selectable' : ''} ${$selected_cards.indexOf(card) !== -1 ? 'selected' : ''}`}
      style={`left: ${getCardPos(cards.length, i)}%; ${getRotateCss(cards.length, i)}`}>
      <svelte:component this={component[face]} {card} selected={$selected_cards.indexOf(card) !== -1} />
    </div>
  {/each}
</div>
