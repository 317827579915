'use strict';

/**
 * The exported function will analyze five cards, to see if it matches any of
 * Big2's five card sets, and gives you the type as well as its associated
 * suit and order (if it matters for breakeven).
 */

const isEqual = require('lodash.isequal');

const isSameSuit = cards => {
  const suit = cards[0].suit;
  return cards.reduce((isSame, x) => isSame && x.suit === suit, true);
};

const isConsecutive = orders => {
  // special: 10 J Q K A are valid.
  if (isEqual(orders, [0, 9, 10, 11, 12])) {
    return true;
  }
  // check regular consecutive orders
  for (let i = 0; i < orders.length - 1; i++) {
    if (orders[i] + 1 !== orders[i + 1]) {
      return false;
    }
  }
  return true;
};

const isFullHouse = orders => {
  // First two a pair?
  if (orders[0] !== orders[1]) {
    return false;
  }
  // Last two a pair?
  if (orders[3] !== orders[4]) {
    return false;
  }
  // Middle card equal to either?
  return orders[2] === orders[1] || orders[2] === orders[3];
};

const isFourOfAKind = orders => {
  // Middle three must be the same
  const middleThree = orders[1] === orders[2] && orders[2] === orders[3];
  return middleThree && (orders[0] === orders[1] || orders[3] === orders[4]);
};

const isRoyalFlush = (cards, orders) => {
  // Must be 10 J Q K A
  return isSameSuit(cards) && isEqual(orders, [0, 9, 10, 11, 12]);
};

module.exports = cards => {
  if (!Array.isArray(cards) || cards.length !== 5) {
    return { type: null };
  }

  const orders = cards.map(x => x.order).sort((a, b) => a - b);

  /**
   * See power-level.js's FiveCard for naming convention
   */

  if (isRoyalFlush(cards, orders)) {
    return { type: 'RoyalFlush', suit: cards[0].suit, order: 13 };
  }
  if (isSameSuit(cards) && isConsecutive(orders)) {
    const order = orders[4]; // highest one is the one to use
    // because this isn't a royal flush, no need to handle the A case. K is largest order.
    return { type: 'StraightFlush', suit: cards[0].suit, order };
  }
  if (isFourOfAKind(orders)) {
    let order = orders[2]; // middle one must be one of the four
    if (order <= 1) {
      order += 13; // Make A and 2 the biggest
    }
    return { type: 'FourOfAKind', suit: null, order };
  }
  if (isFullHouse(orders)) {
    let order = orders[2]; // middle one must be one of the three
    if (order <= 1) {
      order += 13; // Make A and 2 the biggest
    }
    return { type: 'FullHouse', suit: null, order };
  }
  if (isSameSuit(cards)) {
    let order = orders[4]; // last one is usually the largest
    order = orders[0] === 0 ? 13 : order; // if first one is A, then it's actually the largest
    order = orders[0] === 1 || orders[1] === 1 ? 14 : order; // if first or second are 2, then it's largest
    return { type: 'Flush', suit: cards[0].suit, order };
  }
  if (isConsecutive(orders)) {
    let order = orders[4]; // last one is the largest
    let suit = cards.find(x => x.order === order).suit;
    order = orders[0] === 0 && orders[1] === 9 ? 13 : order; // 10 J Q K A
    suit = order === 13 ? cards.find(x => x.order === 0).suit : suit;
    return { type: 'Straight', suit, order };
  }
  return { type: null };
};
