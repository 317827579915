<script>
  import { link } from 'svelte-spa-router';
  import uuid from '../../../lib/uuid';
  import SettingsHud from '../components/settings-hud.svelte';
</script>

<style>
  main {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    text-align: center;
    width: 90%;
  }

  h1 {
    -webkit-text-stroke-width: 7px;
    font-family: serif;
    font-size: 15vw;
    font-weight: bold;
    padding: 4vh 0;
    margin: 6vh 0;
    text-align: center;
    opacity: 0.8;
    position: relative;
    left: 6vw;
    animation: logo-color-anim 2s infinite alternate;
  }

  h1 span {
    position: relative;
    top: 8vh;
    left: -9vw;
    font-size: 8vw;
  }

  @keyframes logo-color-anim {
    0% {
      opacity: 0.7;
      color: #ee9900;
      -webkit-text-stroke-color: #b00;
    }
    100% {
      opacity: 1;
      color: #ffff22;
      -webkit-text-stroke-color: #f00;
    }
  }

  p.game_menu {
    font-size: 4vw;
    font-weight: bold;
    text-align: center;
    color: #888;
    margin: 3vh 3vw;
  }

  @media (max-width: 1000px) {
    h1 {
      -webkit-text-stroke-width: 5px;
    }
  }

  @media all and (orientation: portrait) {
    h1 {
      -webkit-text-stroke-width: 2px;
    }

    h1 span {
      top: 3vh;
    }

    p.game_menu {
      font-size: 30px;
    }
  }

  @media (min-width: 640px) and (orientation: portrait) {
    h1 {
      -webkit-text-stroke-width: 4px;
    }

    h1 span {
      top: 6vh;
    }

    p.game_menu {
      font-size: 45px;
    }
  }

  p.game_menu a {
    color: #fff;
  }

  p.game_menu a:hover {
    text-decoration: none;
  }

  @media all and (orientation: landscape) {
    p.game_menu a span.selector {
      visibility: hidden;
    }

    p.game_menu a:hover span.selector {
      visibility: visible;
    }
  }

  @media all and (orientation: portrait) {
    p.game_menu a span.selector {
      display: none;
    }

    p.game_menu a:hover span.selector {
      display: none;
    }
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>

<main>
  <h1>
    Big2
    <span>.rocks</span>
  </h1>
  <!-- p>No sign up needed, just start new game and send link to friends to play.</p>
  <p>
    The web site uses cookies to remember who you are, so if you close a game half way and come back next time, it
    remembers you were in it. Because of this, be sure that cookies are enabled on your browser!
  </p>
  <p>By starting a new game, you agree to this use of cookies. No personally identifiable information is stored.</p -->
  <p class="game_menu">
    <a href="/game/{uuid()}" use:link>
      <span class="selector">&#128310;</span>
      Online Game
    </a>
  </p>
  <p class="game_menu">
    <a href="/gamelocal" use:link>
      <span class="selector">&#128310;</span>
      Local Game
    </a>
  </p>
</main>

<SettingsHud show_profile={false} />
