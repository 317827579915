'use strict';

/**
 * Power level ranking definition
 */

module.exports = {
  FiveCard: {
    Straight: 1,
    Flush: 2,
    FullHouse: 3,
    FourOfAKind: 4,
    StraightFlush: 5,
    RoyalFlush: 6
  },
  FiveCardBreakEven: {
    Straight: ['order', 'suit'],
    Flush: ['order', 'suit'],
    FullHouse: ['order'],
    FourOfAKind: ['order'],
    StraightFlush: ['order', 'suit'],
    RoyalFlush: ['suit']
  },
  Suit: {
    diamond: 1,
    club: 2,
    heart: 3,
    spade: 4
  }
};
